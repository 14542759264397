<script setup lang="ts">
interface Props {
  digitCount: number
  isError?: string
  placeholder?: string
}
const props = withDefaults(defineProps<Props>(), {
  digitCount: 6,
  isError: null,
  placeholder: 'Enter code here',
})

const emit = defineEmits(['update:otp'])
const digits = ref('')

const handleKeyDown = function (event: KeyboardEvent) {
  if (event.key === 'Backspace')
    return true
  else if (digits.value.length < props.digitCount && ((event.ctrlKey && (event.key === 'c' || event.key === 'v')) || (/^([0-9])$/).test(event.key) === true))
    return true
  else
    event.preventDefault()
}

function handleInput() {
  const value = digits.value
  const numbers = value.replace(/[^0-9]/g, '')
  digits.value = numbers

  emit('update:otp', digits.value)
}
</script>

<template>
  <div>
    <input
      v-model="digits"
      type="tel"
      pattern="[0-9]*"
      inputmode="numeric"
      class="flex-1 focus:outline-none focus:ring-0 w-full h-14 text-center rounded-md border-2"
      :placeholder="props.placeholder"
      :class="`${props.isError ? 'border-rose-500 focus:border-rose-500' : 'border-gray-200 focus:border-gray-200'} ${digits.length > 0 ? 'tracking-[0.8rem] font-bold text-2xl' : ''}`"
      maxlength="6"
      @keydown="handleKeyDown($event)" @input="handleInput()"
    >
    <BaseMoleculesInputError class="mt-2" :invalid-checker="!!props.isError" :error-val="props.isError" />
  </div>
</template>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
